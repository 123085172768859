<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
      <a-button class="add-bt" type="primary" @click="add">新增</a-button>
    </Breadcrumb>
    <!--  -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.id"
      bordered
    >
      <span slot="countryCode" slot-scope="text, record">
        {{ record.countryName }}
        {{ record.provinceName }}
        {{ record.cityName }}
        {{ record.districtName }}
      </span>
      <span slot="address" slot-scope="text, record">
        {{ record.address }}
      </span>
      <span slot="defaultAddress" slot-scope="text, record">
        {{
          record.defaultAddress == 0
            ? "否"
            : record.defaultAddress == 1
            ? "是"
            : ""
        }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          style="margin-right: 20px"
          @click="
            showModal(record)
          "
          >编辑</a
        >
        <a
          style="margin-right: 20px"
          @click="deleteAddress(record.id)"
          href="javascript:;"
          >删除</a
        >
      </span>
    </a-table>
    <a-modal
      v-model="visible"
      :title="modalTitle"
      :footer="null"
      :width="800"
    >
      <AddAddress @success="visible = false; reloadList()" ref="addAddressRef"></AddAddress>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import AddAddress from './dialog/addAddress.vue'

export default {
  components: {
    Breadcrumb,
    AddAddress
  },
  data () {
    return {
      visible: false,
      modalTitle: '新增地址',
      breadList: [
        {
          name: '地址列表',
          url: ''
        }
      ],
      data: [],
      columns: [
        {
          title: '联系人',
          dataIndex: 'contacts',
          ellipsis: true,
          width: 100,
          key: 'contacts'
        },
        {
          title: '联系电话',
          dataIndex: 'tel',
          width: 200,
          key: 'tel',
          ellipsis: true
        },
        {
          title: '所在地区',
          dataIndex: 'scountryCodetatus',
          key: 'countryCode',
          width: 300,
          scopedSlots: {
            customRender: 'countryCode'
          }
        },
        {
          title: '详细地址 ',
          dataIndex: 'address',
          key: 'address',
          width: 300,
          scopedSlots: {
            customRender: 'address'
          }
        },
        {
          title: '默认地址',
          dataIndex: 'defaultAddress',
          key: 'defaultAddress',
          ellipsis: true,
          scopedSlots: {
            customRender: 'defaultAddress'
          }
        },
        {
          title: '操作',
          key: 'action',
          ellipsis: true,
          width: 200,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  async created () {
    this.reloadList()
  },
  computed: {},
  methods: {
    add () {
      this.showModal()
    },
    deleteAddress (id) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除这条内容吗?',
        yes: async (_) => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'deleteTenantAddress',
            query: {
              id: id
            },
            error: (e) => {
              this.$store.dispatch('showToast', {
                type: 'error',
                message: e
              })
            },
            success: () => {
              this.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.reloadList()
            }
          })
        }
      })
    },
    async reloadList () {
      const res = await this.$store.dispatch('http', {
        api: 'getTenantAddress',
        params: {
          pageSize: 100
        },
        error: () => {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '出错了'
          })
        }
      })
      if (res && res.records) {
        this.data = res.records
      }
    },
    showModal (item) {
      this.modalTitle = item ? '编辑地址' : '添加地址'
      this.visible = true
      setTimeout(() => {
        this.$refs.addAddressRef.init(item)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
