<template>
    <div>
        <a-form-model ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }">
            <a-form-model-item label="联系人:" prop="contacts">
                <a-input v-model="form.contacts" placeholder="请输入联系人" />
            </a-form-model-item>
            <a-form-model-item label="联系电话:" prop="tel">
                <a-input v-model="form.tel" placeholder="请输入联系电话" type="number" />
            </a-form-model-item>
            <a-form-model-item prop="provinceCode" label="所在地区">
                <a-cascader :options="options" placeholder="请选择省市区" v-model="selectedAreas" :fieldNames="{label:'name',value:'areaCode',children:'childrens'}" @change="onChange" />
            </a-form-model-item>
            <a-form-model-item label="详细地址:" prop="address">
                <a-input v-model="form.address" placeholder="请输入详细地址" />
            </a-form-model-item>
            <a-form-model-item label="是否默认物流:">
                <a-switch :checked="form.defaultAddress == 1" @change="onDefaultAddressChange" />
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-button :loading="isDetermineLoading" type="primary" @click="onSubmit">
                    保存
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
export default {
  data () {
    return {
      isDetermineLoading: false,
      options: [], // 省市区三级
      selectedAreas: [], // 已选中的 省市区三级
      form: {},
      formTemp: {
        id: undefined,
        tenantId: this.$store.state.user.currentTenantId,
        contacts: '',
        tel: '',
        countryCode: 'CHN',
        countryName: '中国',
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        districtCode: '',
        districtName: '',
        address: '',
        longitude: null,
        latitude: null,
        geoHash: '',
        gmtCreate: '',
        gmtModified: '',
        defaultAddress: 0
      },
      rules: {
        contacts: [{
          required: true,
          message: '联系人不能为空',
          trigger: 'blur'
        }],
        tel: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: 'blur'
          },
          {
            pattern: this.$consts.phoneReg,
            message: '请输入正确的手机号'
          }
        ],
        address: [{
          required: true,
          message: '详细地址不能为空',
          trigger: 'blur'
        }],
        provinceCode: [{
          required: true,
          message: '省市区不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  async created () {

  },
  methods: {
    init (obj) {
      this.$refs.form.clearValidate()
      if (obj && obj.id) {
        this.form = obj
        this.selectedAreas = [obj.provinceCode, obj.cityCode, obj.districtCode]
      } else {
        this.form = this.$utils.clone(this.formTemp)
        this.selectedAreas = []
      }
      this.getCascaderOptions()
    },
    async getCascaderOptions () {
      const res = await this.$store.dispatch('http', {
        api: 'getProvinceCityCounty'
      })
      if (res) {
        this.options = res
      }
    },
    onChange (e, selectedOptions) {
      this.form.provinceName = selectedOptions[0].name
      this.form.provinceCode = selectedOptions[0].areaCode
      this.form.cityName = selectedOptions[1].name
      this.form.cityCode = selectedOptions[1].areaCode
      this.form.districtName = selectedOptions[2].name
      this.form.districtCode = selectedOptions[2].areaCode
      this.selectedList = e
    },
    onDefaultAddressChange (checked) {
      this.form.defaultAddress = checked ? 1 : 0
    },
    onSubmit () {
      if (!this.form.tenantId) {
        this.$store.dispatch('showToast', { type: 'error', message: '租户ID不能为空' })
        return
      }
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = this.form.id ? 'editTenantAddress' : 'editTenantAddress'
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              setTimeout(() => {
                this.isDetermineLoading = false
              }, 500)
            },
            error: () => {
              this.$store.dispatch('showToast', { type: 'error', message: '出错了' })
            },
            success: () => {
              this.$store.dispatch('showToast', { message: '保存成功~' })
              setTimeout(() => {
                this.$emit('success')
              }, 500)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
